import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Navbar from "./Navbar";
import CookieConsent from "react-cookie-consent";
import "./ChatPage.css";
import { useEffect, useState } from "react";
// import { postUserIdentifier } from './utils';
import { postUserIdentifierProxy } from "./utils";
// import { sendUserDetails } from './utils';
import { sendUserDetailsProxy } from "./utils";
// import { sendPhoneRequest } from './utils';
import { sendPhoneRequestProxy } from "./utils";
// import { postUserIdentifierBeacon } from './utils';
import { postUserIdentifierBeaconProxy } from "./utils";
import { getConversationByUserIdentifier } from "./utils";
import { debounce } from "lodash";
import ChatForm from "./ConversationalForm";

function ChatPage() {
  const [userIdentifier, setUserIdentifier] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [companyName, setCompany] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companyNumber, setCompanyNumber] = useState("");
  const [showChatForm, setShowChatForm] = useState(false);
  const [loginLink, setLoginLink] = useState(null);

  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const manageUserIdentiferFromURL = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const userIdentiferFromURL = queryParams.get("userIdentifier");

      if (userIdentiferFromURL && userIdentiferFromURL.length > 0) {
        try {
          const response = await getConversationByUserIdentifier(
            userIdentiferFromURL
          );
          if (response && response.ok) {
            localStorage.setItem("userIdentifier", userIdentiferFromURL);

            const conversationDetailsObj = await response.json();
            const messages = conversationDetailsObj.messages;
            if (messages) {
              const iframe = document.getElementById("super_embed");

              var targetOrigin = "https://www.superdashhq.com";

              iframe.contentWindow.postMessage(
                {
                  action: "updateConversations",
                  conversations: messages,
                },
                targetOrigin
              );

              console.log("Posted messages:", messages);
            }
          } else {
            console.error("Failed to retrieve conversation for user");
          }
        } catch (error) {
          console.error("Error in retrieving conversation:", error);
        }
      }
    };

    // Function to retrieve userParams from localStorage and send them
    const loadAndSendUserDetails = async () => {
      await manageUserIdentiferFromURL();
      // Retrieve userParams from localStorage
      const userParamsJson = localStorage.getItem("userParams");

      if (userParamsJson) {
        const userParams = JSON.parse(userParamsJson);
        // console.log("userParams: ", userParams);

        // Send userParams using the sendUserDetails function
        try {
          // const insertedId = await sendUserDetails(userParams);
          const insertedId = await sendUserDetailsProxy(userParams);
          console.log("Inserted ID:", insertedId);
          // Further actions based on response, if needed

          let storedUserIdentifier = localStorage.getItem("userIdentifier");
          console.log("storedUserIdentifier:", storedUserIdentifier);
          if (!storedUserIdentifier) {
            storedUserIdentifier = insertedId;
            localStorage.setItem("userIdentifier", storedUserIdentifier);
          }
          setUserIdentifier(storedUserIdentifier);
        } catch (error) {
          console.error("Error sending user details:", error);
        }
      } else {
        // get userParams from url instead and sendUserDetails()
        const urlParams = new URLSearchParams(window.location.search);
        const userParams = {
          companyName: urlParams.get("companyName"),
          emailAddress: urlParams.get("emailAddress"),
          firstName: urlParams.get("firstName"),
          lastName: urlParams.get("lastName"),
          phoneNumber: urlParams.get("phoneNumber"),
          companyNumber: urlParams.get("companyNumber"),
        };

        // Send userParams using the sendUserDetails function
        try {
          // const insertedId = await sendUserDetails(userParams);
          const insertedId = await sendUserDetailsProxy(userParams);
          console.log("Inserted ID:", insertedId);

          let storedUserIdentifier = localStorage.getItem("userIdentifier");
          console.log("storedUserIdentifier:", storedUserIdentifier);
          if (!storedUserIdentifier) {
            storedUserIdentifier = insertedId;
            localStorage.setItem("userIdentifier", storedUserIdentifier);
          }

          setUserIdentifier(storedUserIdentifier);
        } catch (error) {
          console.error("Error sending user details from URL:", error);
        }
      }
    };
    loadAndSendUserDetails();

    // const requiredParams = ['firstName', 'lastName', 'emailAddress', 'companyName', 'phoneNumber'];
    // const queryParams = new URLSearchParams(window.location.search);
    // setFirstName(queryParams.get('firstName') || '');
    // setLastName(queryParams.get('lastName') || '');
    // setEmail(queryParams.get('emailAddress') || '');
    // setCompany(queryParams.get('companyName') || '');
    // setPhone(queryParams.get('phoneNumber') || '');
    // setCompanyNumber(queryParams.get('companyNumber') || '');
    // const userParams = JSON.parse(localStorage.getItem('userParams'));

    // // const isParamMissingInURL = requiredParams.some(param => !queryParams.has(param));
    // const isParamMissingInLocalStorage = requiredParams.some(param => !(userParams && userParams[param]));

    // setShowForm(isParamMissingInLocalStorage);

    // Define the required parameters
    const requiredParams = [
      "firstName",
      "lastName",
      "emailAddress",
      "companyName",
      "phoneNumber",
    ];

    // Read the URL query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject = {};

    // Loop through required parameters to populate the paramsObject
    requiredParams.forEach((param) => {
      const value = queryParams.get(param) || "";
      paramsObject[param] = value;
    });

    // Retrieve userParams from localStorage for further checks
    const userParams = JSON.parse(localStorage.getItem("userParams"));

    // Store the parameters into localStorage only if all are provided
    const isParamMissingInURL = requiredParams.some(
      (param) => !queryParams.has(param)
    );
    if (!isParamMissingInURL && !userParams) {
      localStorage.setItem("userParams", JSON.stringify(paramsObject));
    }

    // Check if any required parameter is missing in localStorage
    const isParamMissingInLocalStorage = requiredParams.some(
      (param) => !(userParams && userParams[param])
    );

    // Set the state to show or hide the form
    setShowForm(isParamMissingInLocalStorage);

    // Update the component state using the URL parameters or default empty values
    setFirstName(paramsObject["firstName"]);
    setLastName(paramsObject["lastName"]);
    setEmail(paramsObject["emailAddress"]);
    setCompany(paramsObject["companyName"]);
    setPhone(paramsObject["phoneNumber"]);
    setCompanyNumber(paramsObject["companyNumber"]);

    // secondForm
    const chatFormParams = [
      "loanAmount",
      "loanAcquisitionTimeline",
      "dateOfBirth",
      "address",
      "residentialStatus",
      // "docUpload"
    ];

    const allPresent = chatFormParams.some(
      (param) => !(userParams && userParams[param])
    );
    setShowChatForm(allPresent);
    console.log("showChatForm", showChatForm);

    // Check if all mandatory parameters are present

    const tosAccepted = sessionStorage.getItem("tosAccepted") === "true";
    setTosAccepted(tosAccepted);

    const handleBeforeUnload = (event) => {
      const userIdentifier = localStorage.getItem("userIdentifier"); // Assuming userIdentifier is stored in localStorage
      // const storedUserParams = localStorage.getItem('userParams');
      const userParams = JSON.parse(localStorage.getItem("userParams"));
      if (userIdentifier && userParams) {
        // postUserIdentifier(userIdentifier);

        // postUserIdentifierBeacon(userIdentifier);
        userParams.userIdentifier = userIdentifier;
        console.log("updated userParams from LOCALSTORAGE:", userParams);
        // postUserIdentifierBeacon(userParams);
        postUserIdentifierBeaconProxy(userParams);

        //   console.log('posted useridentifier', userIdentifier);
        // Depending on browser support and requirements, you might not see console logs for async operations in beforeunload
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const userParams = {
          companyName: urlParams.get("companyName"),
          emailAddress: urlParams.get("emailAddress"),
          firstName: urlParams.get("firstName"),
          lastName: urlParams.get("lastName"),
          phoneNumber: urlParams.get("phoneNumber"),
          companyNumber: urlParams.get("companyNumber"),
        };

        userParams.userIdentifier = userIdentifier;
        console.log("updated userParams from URL:", userParams);
        // postUserIdentifierBeacon(userParams);
        postUserIdentifierBeaconProxy(userParams);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showChatForm]);

  console.log("userIdentifier:", userIdentifier);

  const userParams = JSON.parse(localStorage.getItem("userParams") || "{}");
  const iframeSrcBaseUrl =
    "https://www.superdashhq.com/embed?projectID=65afd8e01a495f439c1b6361";
  const iframeParams = new URLSearchParams({
    ...userParams,
    userIdentifier: userIdentifier,
  }).toString();
  const iframeSrcUrl = `${iframeSrcBaseUrl}&${iframeParams}`;
  // console.log(iframeSrcUrl);

  const acceptTerms = () => {
    sessionStorage.setItem("tosAccepted", "true");
    setTosAccepted(true);
  };

  // const handleGetMyLoanClick = () => {
  //     postUserIdentifier(userIdentifier)
  //     // postUserIdentifierBeacon(userIdentifier)
  //     // console.log('posted');
  //       .then(() => {
  //         console.log('User identifier posted successfully');
  //         setShowThankYouMessage(true);
  //       })
  //       .catch((error) => console.error('Failed to post user identifier', error));
  //   };

  const handlePhoneRequestClick = () => {
    // postUserIdentifier(userIdentifier)
    postUserIdentifierProxy(userIdentifier)
      .then((response) => {
        setShowThankYouMessage(true);
        // Assuming postUserIdentifier is adjusted to resolve with the response object
        if (response.ok) {
          console.log("User identifier posted successfully");
        } else if (response.status === 400) {
          // Handle 400 response specifically
          console.log("bad request:", response);
          // handleBadRequest(); // This is a function you would define to handle a 400 Bad Request response.
        }
      })
      .catch((error) => {
        console.error("Failed to post user identifier on phoneRequest", error);
        // Additional error handling here if needed
        userParams.userIdentifier = userIdentifier;
        // sendPhoneRequest(userParams);
        sendPhoneRequestProxy(userParams);
        setShowThankYouMessage(true);
      });
  };

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Gather form values
    const firstName = e.target.elements["first-name"].value;
    const lastName = e.target.elements["last-name"].value;
    const emailAddress = e.target.elements["emailAddress"].value;
    var companyName = e.target.elements["companyName"].value;
    const phoneNumber = e.target.elements["phoneNumber"].value;
    // const emailUpdates = e.target.elements['email-updates'].checked.toString();
    const termsOfService =
      e.target.elements["terms-of-service"].checked.toString();
    const companyNumber = e.target.elements["companyNumber"]
      ? e.target.elements["companyNumber"].value
      : "";
    // const userIdentifier = e.target.elements['userIdentifier']

    if (companyName) {
      companyName = toTitleCase(companyName);
    }

    // Construct the data object conditionally
    let data = {
      firstName,
      lastName,
      emailAddress,
      companyName,
      phoneNumber,
      // emailUpdates,
      termsOfService, // Uncomment if needed
    };

    // Include companyNumber only if it exists
    if (companyNumber) {
      data.companyNumber = companyNumber;
    }

    const currentQueryParams = new URLSearchParams(window.location.search);
    const userIdentiferFromURL = currentQueryParams.get("userIdentifier");

    if (userIdentiferFromURL) {
      data.userIdentifier = userIdentiferFromURL;
    }

    // Construct URL with query parameters from data object
    const queryParams = new URLSearchParams(data).toString();

    // Save to localStorage
    localStorage.setItem("userParams", JSON.stringify(data));

    data.formSubmitted = true;
    // const insertedId = await sendUserDetails(data);
    const insertedId = await sendUserDetailsProxy(data);
    console.log("Inserted ID:", insertedId);

    sessionStorage.setItem("tosAccepted", termsOfService);
    window.location.href = `${window.location.pathname}?${queryParams}`;
  };

  // Fetch company suggestions
  const fetchCompanySuggestions = debounce(async (query) => {
    if (!query) {
      setCompanySuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `/api/proxy?query=${encodeURIComponent(query)}`
      );
      if (!response.ok) throw new Error("Failed to fetch");

      // console.log('response', await response.text());
      const suggestions = await response.json();

      // console.log('suggestions:', suggestions);

      setCompanySuggestions(suggestions.items);
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
    }
  }, 300); // Adjust debounce timing as needed

  useEffect(() => {
    const userParameters = JSON.parse(localStorage.getItem("userParams"));
    const loanAmount = parseInt(userParameters?.loanAmount || "0", 10);
    const fileUpload = userParameters?.fileUpload;
    const emailAddress = userParameters?.emailAddress;

    // Polling should only start if loanAmount is less than 50,000 and fileUpload is not true
    if (loanAmount < 100000 && fileUpload !== true) {
      const intervalId = setInterval(() => {
        fetchLoginLink(intervalId); // Pass the intervalId to the fetch function
      }, 5000); // Poll every 5 seconds

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const fetchLoginLink = async (intervalId) => {
    const userIdentifier = localStorage.getItem("userIdentifier");
    try {
      const response = await fetch(
        `/api/pollLoginLink?userIdentifier=${userIdentifier}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data.loginLink) {
          setLoginLink(data.loginLink);
          clearInterval(intervalId); // Stop polling once login link is found
        }
      }
    } catch (error) {
      console.error("Error fetching login link:", error);
    }
  };

  const handleOpenLoginLink = () => {
    if (loginLink) {
      window.open(loginLink, "_blank");
    }
  };

  // const startAgain = () => {

  //     const userIdentifier = localStorage.getItem('userIdentifier');
  //     postUserIdentifierBeacon(userIdentifier);
  //     console.log('posted useridentifier', userIdentifier);

  //     localStorage.clear();
  //     console.log('Console cleared');

  //     // Clear local storage

  //     // Add clearLocalStorage=true parameter to iframeSrcUrl
  //     const updatedIframeSrcUrl = `${iframeSrcUrl}&clearLocalStorage=true`;

  //     const iframe = document.getElementById('super_embed');

  //     // Define a function to handle page reload
  //     const reloadPage = () => {
  //         const currentUrl = window.location.href;
  //         // Reload the page with the updated URL
  //         window.location.href = currentUrl.toString();
  //     };

  //     // Set onload event listener for the iframe
  //     iframe.onload = () => {
  //         // const userIdentifier = localStorage.getItem('userIdentifier');
  //         // postUserIdentifierBeacon(userIdentifier);
  //         // console.log('posted useridentifier', userIdentifier);
  //         // Call reloadPage function after iframe finishes loading
  //         reloadPage();
  //     };

  //     // Reload the iframe content with the updated URL
  //     iframe.src = updatedIframeSrcUrl;
  // };

  // async function fetchConversation(userIdentifier) {
  //     try {
  //       const conversationDetails = await getConversationByUserIdentifier(userIdentifier);
  //       const conversationDetailsObj = await conversationDetails.json()
  //       const messages = conversationDetailsObj.messages

  //       if (messages) {
  //         const iframe = document.getElementById('super_embed');

  //         var targetOrigin = "https://www.superdashhq.com";

  //         iframe.contentWindow.postMessage({
  //             action: "updateConversations",
  //             conversations: conversationDetails
  //         }, targetOrigin);

  //         console.log('Conversation Details:', conversationDetails);

  //       } else {
  //         console.log('No conversation details found or error occurred');
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch conversation:', error);
  //     }
  //   }

  //   Call the function
  // fetchConversation('66154a0c3c1b641d7ce6b2d9');

  return (
    <div className="flex flex-col bg-white text-black">
      <Navbar />
      {!tosAccepted && !showForm ? (
        // Terms of Service Modal
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-lg text-center w-full max-w-md mx-4 sm:mx-0">
            <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
            <p className="mb-4">
              Please review the Greenwood Capital Advisory Ltd{" "}
              <a
                className="text-blue-500 hover:underline"
                href="https://www.greenwoodcapital.co.uk/terms-and-conditions"
              >
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                className="text-blue-500 hover:underline"
                href="https://www.greenwoodcapital.co.uk/terms-and-conditions-2"
              >
                Privacy Policy
              </a>{" "}
              before continuing to use the site. Please also accept{" "}
              <a
                className="text-blue-500 hover:underline"
                href="https://junoai.notion.site/TERMS-OF-SERVICE-d6d27951622b4f5f9202de2394a7e6ad?pvs=4"
              >
                Terms of Service
              </a>{" "}
              for Juno Technologies Ltd, our service provider, to continue using
              our site.
            </p>
            <p className="mb-4">
              We plan to show you credit offers from carefully selected
              providers. These providers will share your information with credit
              reference agencies to run soft credit and identity checks, but
              don't worry this will not affect your credit score. They will also
              share it with fraud prevention agencies - please be aware, if they
              do spot fraud, you or others could be refused services, finance,
              or employment.
            </p>
            <button
              onClick={acceptTerms}
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              I Accept All
            </button>
          </div>
        </div>
      ) : (
        <main className="flex-1 overflow-hidden">
          <section className="chat-page relative w-full py-4 md:py-10 lg:py-10 xl:py-10 bg-consilium-primary">
            <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
              {showForm ? (
                <div className="space-y-5 px-4">
                  <h1 className="text-white text-5xl md:text-6xl lg:text-7xl font-poppins font-bold tracking-tighter mb-2 md:mb-4 px-4">
                    Find funding fast
                  </h1>
                  <hr className="text-white"></hr>
                  <p className="mx-auto max-w-[700px] text-black font-manrope font-bold md:text-xl px-4">
                    {/* Apply in seconds. */}
                  </p>
                </div>
              ) : (
                <div className="space-y-5 px-4">
                  <h1 className="text-white text-5xl md:text-6xl lg:text-7xl font-poppins tracking-tighter mb-2 md:mb-4 px-4">
                    <span className="text-call-to-action font-cormorant-garamond italic text-6xl md:text-7xl lg:text-8xl font-bold">
                      Fast-Track
                    </span>{" "}
                    Your Loan Application
                  </h1>
                  <hr className="text-white"></hr>
                  <p className="mx-auto max-w-[700px] text-white font-manrope md:text-xl px-4">
                    Just answer a few more questions.
                  </p>
                </div>
              )}
            </div>
          </section>

          {showForm ? (
            <section className="flex flex-col justify-center items-center p-6">
              <div className="pb-8">
                <p className="mx-auto max-w-[700px] text-white font-manrope px-4">
                  Checking your eligibility won't affect your credit rating.
                </p>
              </div>

              <form onSubmit={handleSubmit} className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="first-name"
                    >
                      First Name
                    </label>
                    <input
                      required
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="first-name"
                      type="text"
                      placeholder="Jane"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="last-name"
                    >
                      Last Name
                    </label>
                    <input
                      required
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="last-name"
                      type="text"
                      placeholder="Doe"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="emailAddress"
                    >
                      Email
                    </label>
                    <input
                      required
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="emailAddress"
                      type="email"
                      placeholder="jane.doe@acme.com"
                      value={emailAddress}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="companyName">
                                        Company Name
                                    </label>
                                    <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="companyName" type="text" placeholder="Acme Inc" value={companyName} onChange={(e) => setCompany(e.target.value)}/> */}
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="companyName"
                    >
                      Company Name
                    </label>
                    <input
                      required
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="companyName"
                      type="text"
                      placeholder="Acme Inc"
                      value={companyName}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        fetchCompanySuggestions(e.target.value);
                      }}
                    />
                    {companySuggestions.length > 0 && (
                      <ul className="absolute z-10 list-disc bg-white border mt-1 max-h-60 overflow-auto">
                        {companySuggestions.map((company, index) => (
                          <li
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              setCompany(company.title); // Set the company name
                              setCompanyNumber(company.company_number); // Also set the company number
                              setCompanySuggestions([]); // Clear suggestions
                            }}
                          >
                            {company.title}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                {companyNumber && (
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="companyNumber"
                      >
                        Company Number
                      </label>
                      <input
                        required
                        readOnly // Make it read-only if users shouldn't change it
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="companyNumber"
                        type="text"
                        value={companyNumber}
                        onChange={(e) => setCompanyNumber(e.target.value)} // This handler can be removed if the field is read-only
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="phoneNumber"
                    >
                      Phone Number
                    </label>
                    <input
                      required
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="phoneNumber"
                      type="text"
                      placeholder="+447012 345678"
                      value={phoneNumber}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <div className="flex items-center mb-4">
                      {/* <input id="email-updates" name="email-updates" type="checkbox" defaultChecked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/> */}
                      {/* <label htmlFor="email-updates" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Keep in touch via email
                                        </label> */}
                    </div>
                    <div className="flex items-center"></div>
                    <input
                      required
                      id="terms-of-service"
                      name="terms-of-service"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="terms-of-service"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Accept{" "}
                      <a
                        className="font-bold text-blue-900 hover:text-consilium-darker"
                        href="https://www.greenwoodcapital.co.uk/terms-and-conditions"
                      >
                        Terms & Conditions (Greenwood Capital)
                      </a>
                      ,{" "}
                      <a
                        className="font-bold text-blue-900 hover:text-consilium-darker"
                        href="https://www.greenwoodcapital.co.uk/terms-and-conditions-2"
                      >
                        Privacy Policy (Greenwood Capital)
                      </a>{" "}
                      and{" "}
                      <a
                        className="font-bold text-blue-900 hover:text-consilium-darker"
                        href="https://junoai.notion.site/TERMS-OF-SERVICE-d6d27951622b4f5f9202de2394a7e6ad"
                      >
                        Terms of Service (Juno Technologies){" "}
                      </a>
                    </label>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    className="shadow bg-black hover:bg-consilium-darker focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="submit"
                  >
                    Check Eligibility
                  </button>
                </div>
                <div>
                  <p className="text-center text-sm pt-6">
                    We plan to show you credit offers from carefully selected
                    providers. These providers will share your information with
                    credit reference agencies to run soft credit and identity
                    checks; don't worry, this will not affect your credit score.
                    They will also share it with fraud prevention agencies -
                    please be aware, if they do spot fraud, you or others could
                    be refused services, finance, or employment.
                  </p>
                </div>
              </form>
            </section>
          ) : (
            <div>
              {showThankYouMessage ? (
                <div className="flex flex-col min-h-[500px] justify-center items-center p-6">
                  <h2 className="text-3xl font-bold font-manrope mb-4">
                    Thank You!
                  </h2>
                  <p className="font-manrope">
                    We've received your application and will be in touch soon.
                  </p>
                </div>
              ) : showChatForm ? (
                <ChatForm />
              ) : (
                <div>
                  <section className="relative chat-page flex-1 flex justify-center p-6 bg-white">
                    <div className="w-full md:w-2/3 lg:w-1/3 px-4 sm:px-6 overflow-hidden h-auto min-h-[500px] max-h- flex flex-col justify-center items-center">
                      {/* <iframe
                                        id="super_embed"
                                        title="superdash_embed"
                                        className="w-full mb-4 rounded-lg"
                                        src={iframeSrcUrl}
                                        style={{ minHeight: '500px' }} 
                                    ></iframe> */}
                      {/* {loginLink && (
                                        // <div className="flex flex-col min-h-[500px] justify-center items-center">
                                            <div className="flex flex-col justify-center items-center">
                                            <p>Good news! It looks like you're eligible to apply directly with one of our partners.</p>
                                            <button
                                                onClick={handleOpenLoginLink}
                                                className="text-black hover:bg-consilium-darker hover:text-black bg-call-to-action border border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                            >
                                                Connect to lender &#8594;
                                            </button>
                                        </div>
                                    )} */}
                      {/* <div className="flex flex-col min-h-[500px] justify-center items-center p-6"> */}
                      <div className="flex flex-col min-h-[500px] justify-center items-center p-6">
                        <h2 className="text-3xl font-bold font-manrope mb-4">
                          Thank You!
                        </h2>
                        <p className="font-manrope">
                          We've received your application and will be in touch
                          soon.
                        </p>
                        {loginLink && (
                          // <div className="flex flex-col min-h-[500px] justify-center items-center">
                          <div className="font-manrope flex flex-col justify-center items-center text-center mt-4">
                            <p className="text-xl font-bold">
                              Good news!<br></br> It looks like you're eligible
                              to apply directly with one of our partners.
                            </p>
                            <button
                              onClick={handleOpenLoginLink}
                              className="text-black hover:bg-consilium-darker hover:text-black bg-call-to-action border border-black font-bold font-manrope px-8 py-4 text-center text-lg mr-2 mb-2 rounded mt-4"
                            >
                              Connect to lender &#8594;
                            </button>
                          </div>
                        )}
                      </div>

                      {/* <button
                                        // onClick={handleGetMyLoanClick}
                                        // className="text-white bg-black hover:bg-consilium-darker hover:text-black border border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                        onClick={startAgain}
                                        className="text-black hover:bg-consilium-darker hover:text-black border border-black font-bold font-manrope px-5 py-2.5 text-center mr-2 mb-2 rounded"
                                    >
                                        Start Again
                                    </button>  */}
                    </div>
                  </section>
                  {/* <section className="chat-page relative w-full py-4 md:py-10 lg:py-10 xl:py-10 bg-consilium-primary">
                                <div className="text-center mt-4 md:mt-0 flex-grow flex items-center justify-center">
                                    <div className="space-y-5 m-4">
                                        <p className="mx-auto max-w-[700px] text-white font-manrope md:text-xl">
                                            Rather chat on the phone?
                                        </p>
                                        <button 
                                        className="bg-transparent hover:bg-call-to-action text-white font-manrope hover:text-black py-2 px-4 border border-white hover:border-black rounded"
                                        onClick={handlePhoneRequestClick}
                                        >
                                            Talk to the team
                                        </button>
                                    </div>
                                </div>
                            </section> */}
                </div>
              )}
            </div>
          )}
        </main>
      )}
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-black bg-black">
        <p className="text-xs text-white">
          © Juno Technologies Ltd. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <Link
            className="text-xs text-white hover:underline underline-offset-4"
            to="https://junoai.notion.site/TERMS-OF-SERVICE-d6d27951622b4f5f9202de2394a7e6ad?pvs=4"
          >
            Juno Terms of Service
          </Link>
          {/* <Link className="text-xs text-white hover:underline underline-offset-4" to="https://www.termsfeed.com/live/4f3ebefe-fb02-4b06-818c-66a22151d721">
                        Privacy
                    </Link> */}
        </nav>
      </footer>

      <CookieConsent
        location="bottom"
        buttonText="Okay"
        style={{ background: "black", color: "white" }}
        buttonStyle={{ background: "white", color: "black", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default ChatPage;
